import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';
import { apiWrapper } from '../../api/apiWrapper';

export const thunks = {
  getStates: createThunk('general/getStates', () =>
    api.get(WEB_API_ROUTES.general.getStates)
  ),

  getCities: createThunk('general/getCities', () =>
    api.get(WEB_API_ROUTES.general.getCities)
  ),

  getRooms: createThunk('general/getRooms', () =>
    api.get(WEB_API_ROUTES.general.rooms)
  ),

  getTeachers: createThunk('general/getTeachers', () =>
    api.get(WEB_API_ROUTES.general.teachers)
  ),

  getUsers: createThunk('general/getUsers', () =>
    api.get(WEB_API_ROUTES.general.users)
  ),

  search: createThunk('general/search', ({ query, category }) =>
    api.get(WEB_API_ROUTES.general.search, { params: { query, category } })
  ),

  getProfile: createThunk('general/getProfile', () =>
    apiWrapper.get(WEB_API_ROUTES.general.profile)
  ),

  getRecipientsList: createThunk('general/getRecipientsList', () =>
    api.get(WEB_API_ROUTES.notifications.recipientsList)
  ),

  sendEmail: createThunk('general/sendEmail', (data) =>
    api.post(WEB_API_ROUTES.notifications.sendEmail, data)
  ),

  sendSms: createThunk('general/sendSms', (data) =>
    api.post(WEB_API_ROUTES.notifications.sendSms, data)
  ),

  getStudents: createThunk('general/getStudents', () =>
    api.get(WEB_API_ROUTES.general.students)
  ),

  getRoutineStatuses: createThunk('general/getRoutineStatuses', () =>
    api.get(WEB_API_ROUTES.general.routineStatuses)
  ),

  getStyles: createThunk('general/getStyles', () =>
    api.get(WEB_API_ROUTES.general.styles)
  ),

  getRoutineTypes: createThunk('general/getRoutineTypes', () =>
    api.get(WEB_API_ROUTES.general.routineTypes)
  ),

  getRoutineLevels: createThunk('general/getRoutineLevels', () =>
    api.get(WEB_API_ROUTES.general.routineLevels)
  ),
};
