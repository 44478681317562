import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { createThunk } from '../../utils/storeUtils';

const thunks = {
  submitEventCreation: createThunk(
    'createEvent/submitEventCreation',
    (data) => api.post(WEB_API_ROUTES.events.list, data),
    'New event created'
  ),
  editEvent: createThunk(
    'createEvent/editEvent',
    ({ eventId, data }) =>
      api.put(WEB_API_ROUTES.events.info.replace('{eventId}', eventId), data),
    'Event successfully edited'
  ),
};

export { thunks };
