const selectors = {
  list: (state) => state.events.list,
  filters: (state) => state.events.filters,
  pagination: (state) => state.events.pagination,
  recipients: (state) => state.events.recipients,

  loadingListStatus: (state) => state.events.loadingListStatus,
  loadingRecipientsStatus: (state) => state.events.loadingRecipientsStatus,
};

export { selectors };
