import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Card } from 'components/Card';
import { ReactComponent as DancersIcon } from 'assets/img/global/icons/person-tick.svg';
import {
  ChevronContainer,
  EditButton,
  PersonalInfo,
  Row,
  Wrap,
} from 'pages/User/_shared/entityDetails/styled';
import { APP_ROUTES } from 'router/appRoutes';
import {
  CHEVRON_SVG,
  EVENT_SVG,
  INFO_SVG,
  PENCIL_SVG,
  ROUTINES_SVG,
  SCHEDULE_SVG,
  SCORE_SVG,
} from 'components/ui/_shared/SVG';
import { status } from 'utils/const';
import { performanceDetails } from 'store/performanceDetails';
import { getPerformanceDateTime } from 'utils/dates';
import { createPerformance } from 'store/createPerformance';
import { events } from 'store/events';
import { routines } from 'store/routines';
import { CardLoader } from 'pages/User/_shared/entityDetails/CardLoader';
import { CardHeaderStyled } from '../../_shared/styled';
import { DancersWrap, Icon, Info, LinkStyled } from './styled';

export const PerformanceInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(performanceDetails.selectors.performanceInfo);
  const eventsList = useSelector(events.selectors.list);
  const routinesList = useSelector(routines.selectors.routinesList);
  const dataLoadingStatus = useSelector(
    performanceDetails.selectors.loadingPerformanceInfoStatus
  );
  const isLoading = dataLoadingStatus === status.PENDING;
  const dataLoaded = dataLoadingStatus === status.SUCCESS;

  const routineTypes = data?.routine_types?.join(', ');

  const onRedirectToEditPage = () => {
    const isRoutine = data?.is_routine;

    dispatch(
      createPerformance.actions.setobjectForCreatePerformance({
        name: isRoutine ? data.routine_title : data.event_name,
        dancers: data.dancers,
        objectList: isRoutine
          ? eventsList.map((event) => ({
              label: event.name,
              value: event.id,
            }))
          : routinesList.map((routine) => ({
              label: routine.title,
              value: routine.id,
              dancers: routine.dancers.map((d) => ({
                label: d.full_name,
                value: d.id,
                path: d.image,
              })),
            })),
        isRoutine: data.is_routine,
      })
    );

    navigate(`${APP_ROUTES.user.performances.create}?edit=${id}`);
  };

  const onOpenDancers = () => {
    dispatch(performanceDetails.actions.setPageTab('dancers'));
  };

  if (isLoading) {
    return <CardLoader />;
  }

  return (
    <Card>
      {dataLoaded && (
        <Wrap>
          <div>
            <CardHeaderStyled>
              <div>Performance Info</div>
              <EditButton onClick={onRedirectToEditPage}>
                <PENCIL_SVG size={24} />
              </EditButton>
            </CardHeaderStyled>
          </div>

          <PersonalInfo>
            <Row>
              <div>
                <SCHEDULE_SVG color="#BEBEE9" />
              </div>

              <div>
                <strong>Date</strong>
                <p>
                  {getPerformanceDateTime(data?.date, data?.time, true) || '-'}
                </p>
              </div>
            </Row>

            <Row>
              <div>
                <EVENT_SVG color="#BEBEE9" />
              </div>

              <div>
                <strong>Event</strong>
                {data.event_name ? (
                  <p>
                    <LinkStyled
                      to={APP_ROUTES.user.events.details.index.replace(
                        ':id',
                        data.event_id
                      )}
                    >
                      @{data.event_name}
                    </LinkStyled>
                  </p>
                ) : (
                  <p>-</p>
                )}
              </div>
            </Row>

            <Row>
              <div>
                <INFO_SVG color="#BEBEE9" />
              </div>

              <div>
                <strong>Event Entry ID</strong>
                <p>{data.entryId || '-'}</p>
              </div>
            </Row>

            <Row>
              <div>
                <ROUTINES_SVG color="#BEBEE9" />
              </div>

              <div>
                <strong>Routine</strong>

                {data.routine_title ? (
                  <Row style={{ display: 'flex' }}>
                    <LinkStyled
                      to={APP_ROUTES.user.routines.details.index.replace(
                        ':id',
                        data.routine_id
                      )}
                    >
                      #{data.routine_title}
                    </LinkStyled>
                    &nbsp;{routineTypes?.length ? `(${routineTypes})` : ''}
                  </Row>
                ) : (
                  <p>'-'</p>
                )}
              </div>
            </Row>

            <Row>
              <div>
                <SCORE_SVG color="#BEBEE9" />
              </div>

              <div>
                <strong>Score</strong>
                <p>{data.score || '-'}</p>
              </div>
            </Row>
          </PersonalInfo>

          <DancersWrap>
            <Icon>
              <DancersIcon />
            </Icon>
            <Info onClick={onOpenDancers}>
              <strong>Total Dancers</strong>
              <span>{data.dancersCount}</span>
            </Info>
            <ChevronContainer
              css={{ marginLeft: 'auto' }}
              onClick={onOpenDancers}
            >
              <CHEVRON_SVG
                direction="right"
                size="24px"
                thickness="2"
              />
            </ChevronContainer>
          </DancersWrap>
        </Wrap>
      )}
    </Card>
  );
};
