import { WEB_API_ROUTES } from 'api/api-routes';
import { createThunk } from 'utils/storeUtils';
import { apiWrapper } from '../../api/apiWrapper';
import api from '../../api';

export const thunks = {
  getList: createThunk('media/getList', () =>
    apiWrapper.get(WEB_API_ROUTES.media.list)
  ),
  addMedia: createThunk('media/addMedia', (data) =>
    api.post(WEB_API_ROUTES.media.list, data)
  ),
};
