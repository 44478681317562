import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  getRoutinePerformanceDancers: createThunk(
    'createPerformance/getRoutinePerformanceDancers',
    ({ routineId, performanceId }) =>
      api.get(
        WEB_API_ROUTES.performances.routineDancers
          .replace('{routineId}', routineId)
          .replace('{performanceId}', performanceId)
      )
  ),

  submitCreation: createThunk(
    'createPerformance/submitCreation',
    ({ id, data, isRoutine }) => {
      const endpoint = isRoutine
        ? WEB_API_ROUTES.routines.performancesInfo.replace('{routineId}', id)
        : WEB_API_ROUTES.events.performancesInfo.replace('{eventId}', id);

      return api.post(endpoint, data);
    }
  ),
};
