import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const defaultValues = {
  name: '',
  level: { label: '', value: '' },
  location: { label: '', value: '' },
  styles: [],
  styleSearch: '',
  teachers: [],
  teacherSearch: '',
  description: '',
  isActive: false,
};

const initialState = {
  defaultClassInfo: defaultValues,
  submitCreationStatus: status.IDLE,
};

const slice = createSlice({
  name: 'createClass',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.submitCreation.pending, (state) => {
        state.submitCreationStatus = status.PENDING;
      })
      .addCase(thunks.submitCreation.fulfilled, (state) => {
        state.submitCreationStatus = status.SUCCESS;
      })
      .addCase(thunks.submitCreation.rejected, (state) => {
        state.submitCreationStatus = status.FAIL;
      });
  },
});

const createClass = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createClass };
export default slice.reducer;
