import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';
import { apiWrapper } from '../../api/apiWrapper';

const thunks = {
  getList: createThunk('classes/getList', () =>
    apiWrapper.get(WEB_API_ROUTES.classes.list)
  ),

  getClassRecipients: createThunk('classes/getClassRecipents', (classId) =>
    apiWrapper.get(
      WEB_API_ROUTES.classes.recipientsList.replace('{classId}', classId)
    )
  ),

  sendEmail: createThunk(
    'classDetails/sendEmail',
    ({ classId, data }) =>
      api.post(
        WEB_API_ROUTES.classes.email.replace('{classId}', classId),
        data
      ),
    'Message sent'
  ),

  sendSms: createThunk(
    'classDetails/sendSms',
    ({ classId, data }) =>
      api.post(WEB_API_ROUTES.classes.sms.replace('{classId}', classId), data),
    'Message sent'
  ),
};

export { thunks };
