import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';
import { apiWrapper } from '../../api/apiWrapper';

export const thunks = {
  getDancersList: createThunk('dancers/logIn', () =>
    apiWrapper.get(WEB_API_ROUTES.dancers.list)
  ),

  sendEmail: createThunk(
    'dancers/sendEmail',
    ({ subject, messageText, messageFor }) =>
      api.post(WEB_API_ROUTES.dancers.email, {
        messageFor,
        subject,
        messageText,
      }),
    'Message sent'
  ),

  sendSms: createThunk(
    'dancers/sendSms',
    ({ messageText, messageFor }) =>
      api.post(WEB_API_ROUTES.dancers.sms, {
        messageFor,
        messageText,
      }),
    'Message sent'
  ),
};
