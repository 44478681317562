import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';
import { createParent } from '../createParent';

export const thunks = {
  getParentInfo: createThunk('parentDetails/getParentInfo', ({ parentId }) =>
    api.get(WEB_API_ROUTES.parents.parentInfo.replace('{parentId}', parentId))
  ),

  updateParentInfo: createThunk(
    'parentDetails/updateParentInfo',
    ({ parentId, data }) =>
      api.post(
        WEB_API_ROUTES.parents.parentInfo.replace('{parentId}', parentId),
        data
      )
  ),

  createRelative: createThunk(
    'parentDetails/createRelative',
    async ({ data, _id }, { dispatch }) => {
      const { data: relativeData } = await api.post(
        WEB_API_ROUTES.parents.createRelative,
        data
      );

      const newRelativeId = {
        value: relativeData.data.relative.id,
        label: `${relativeData.data.relative.first_name} ${relativeData.data.relative.last_name}`,
      };

      dispatch(createParent.actions.addCreatedRelative(newRelativeId));

      return {
        data: {
          relative: { ...relativeData.data.relative, id: newRelativeId },
          _id,
        },
      };
    },
    'Child successfully added to the system'
  ),

  getNotifications: createThunk('parentDetails/getNotifications', (parentId) =>
    api.get(
      WEB_API_ROUTES.parents.notifications.replace('{parentId}', parentId)
    )
  ),

  getDancersList: createThunk('parentDetails/getDancersList', ({ parentId }) =>
    api.get(WEB_API_ROUTES.parents.dancers.replace('{parentId}', parentId))
  ),

  getClasses: createThunk('parents/getDancers', ({ parentId }) =>
    api.get(
      WEB_API_ROUTES.parents.parentClasses.replace('{parentId}', parentId)
    )
  ),
};
