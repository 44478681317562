const selectors = {
  getAppointmentsStatus: (state) => state.scheduler.getAppointmentsStatus,
  studentForPrivateCreate: (state) => state.scheduler.studentForPrivateCreate,
  classForGroupCreate: (state) => state.scheduler.classForGroupCreate,

  parents: (state) => state.scheduler.parents,
  appointments: (state) => state.scheduler.appointments,
  classes: (state) => state.scheduler.classes,

  generalEventTypes: (state) => state.scheduler.filters.generalEventTypes,
  resourceType: (state) => state.scheduler.filters.resourceType,
  eventTypes: (state) => state.scheduler.filters.eventTypes,
  selectedLocations: (state) => state.scheduler.filters.locations,
  selectedTeachers: (state) => state.scheduler.filters.teachers,
  selectedStudents: (state) => state.scheduler.filters.students,
};

export { selectors };
