import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createDancer } from 'store/createDancer';
import { createThunk } from '../../utils/storeUtils';
import { apiWrapper } from '../../api/apiWrapper';

const thunks = {
  getDancerUpcomingAppointments: createThunk(
    'dancerDetails/getDancerUpcomingAppointments',
    ({ dancerId }) =>
      api.get(
        WEB_API_ROUTES.dancers.appointments.replace('{dancerId}', dancerId)
      )
  ),

  getDancerInfo: createThunk('dancerDetails/getDancerInfo', ({ dancerId }) =>
    apiWrapper.get(WEB_API_ROUTES.dancers.info.replace('{dancerId}', dancerId))
  ),

  createGuardian: createThunk(
    'dancerDetails/createGuardian',
    async ({ data, _id }, { dispatch }) => {
      const { data: guardianData } = await api.post(
        WEB_API_ROUTES.dancers.createRelative,
        data
      );

      const newRelativeId = {
        value: guardianData.data.relative.id,
        label: `${guardianData.data.relative.first_name} ${guardianData.data.relative.last_name}`,
      };

      dispatch(createDancer.actions.addRelative(newRelativeId));

      return {
        data: {
          relative: { ...guardianData.data.relative, id: newRelativeId },
          _id,
        },
      };
    },
    'Relative successfully added to the system'
  ),

  updateDancerInfo: createThunk(
    'dancerDetails/updateDancerInfo',
    ({ dancerId, data }) =>
      api.post(
        WEB_API_ROUTES.dancers.info.replace('{dancerId}', dancerId),
        data
      )
  ),

  getDancerEvaluationInfo: createThunk(
    'dancerDetails/getDancerEvaluationInfo',
    ({ dancerId }) =>
      apiWrapper.get(
        WEB_API_ROUTES.dancers.evaluation.replace('{dancerId}', dancerId)
      )
  ),

  updateDancerEvaluation: createThunk(
    'dancerDetails/updateDancerEvaluation',
    ({ dancerId, data }) =>
      api.put(
        WEB_API_ROUTES.dancers.evaluation.replace('{dancerId}', dancerId),
        {
          evaluations: data,
        }
      )
  ),

  getPerformances: createThunk('dancerDetails/getPerformances', (dancerId) =>
    api.get(WEB_API_ROUTES.dancers.performance.replace('{dancerId}', dancerId))
  ),

  getRatings: createThunk('dancerDetails/getRatings', ({ dancerId }) =>
    api.get(WEB_API_ROUTES.dancers.ratings.replace('{dancerId}', dancerId))
  ),

  getNotifications: createThunk('dancerDetails/getNotifications', (dancerId) =>
    api.get(
      WEB_API_ROUTES.dancers.notifications.replace('{dancerId}', dancerId)
    )
  ),

  getDancerClasses: createThunk(
    'dancerDetails/getDancerClasses',
    ({ dancerId }) =>
      api.get(WEB_API_ROUTES.dancers.classes.replace('{dancerId}', dancerId))
  ),

  addDancerToClasses: createThunk(
    'dancerDetails/addDancerToClass',
    ({ dancerId, classes }) =>
      api.put(WEB_API_ROUTES.dancers.classes.replace('{dancerId}', dancerId), {
        classes,
      })
  ),

  getDancerRoutines: createThunk(
    'dancerDetails/getDancerRoutines',
    (dancerId) =>
      api.get(WEB_API_ROUTES.dancers.routines.replace('{dancerId}', dancerId))
  ),

  joinRoutines: createThunk(
    'dancerDetails/joinRoutines',
    ({ dancerId, routines }) =>
      api.put(WEB_API_ROUTES.dancers.routines.replace('{dancerId}', dancerId), {
        routines,
      })
  ),
};

export { thunks };
