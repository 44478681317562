import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  getList: createThunk('events/getList', () =>
    api.get(WEB_API_ROUTES.events.list)
  ),

  getRecipients: createThunk('events/getRecipients', (eventId) =>
    api.get(WEB_API_ROUTES.events.recipientsList.replace('{eventId}', eventId))
  ),

  sendEmail: createThunk(
    'events/sendEmail',
    ({ eventId, data }) =>
      api.post(
        WEB_API_ROUTES.events.sendEmail.replace('{eventId}', eventId),
        data
      ),
    'Message sent'
  ),

  sendSms: createThunk(
    'events/sendSms',
    ({ eventId, data }) =>
      api.post(
        WEB_API_ROUTES.events.sendSms.replace('{eventId}', eventId),
        data
      ),
    'Message sent'
  ),
};
