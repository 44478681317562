import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { scheduleDefaultValuesModel } from 'models/scheduleDefaultValuesModel';
import { pricingDefaultValuesModel } from 'models/pricingDefaultValuesModel';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  classInfo: {},
  loadingClassInfoStatus: status.IDLE,
  classAppointments: [],
  loadingAppointmentsStatus: status.IDLE,
  dancers: {
    list: [],
    loadingClassDancersStatus: status.IDLE,
    currentPage: 1,
  },
  schedule: {
    data: null,
    list: [],
    pagination: {
      current: 1,
    },
    editMode: false,
    submittedValuesForCalculation: null,
    scheduleCalculation: null,
    checkingClassScheduleStatus: status.IDLE,
    savingClassScheduleStatus: status.IDLE,
    getClassScheduleListStatus: status.IDLE,
    updateClassScheduleInfoStatus: status.IDLE,
  },
  pricing: {
    data: null,
    editMode: false,
    creatingClassPricingStatus: status.IDLE,
    gettingClassPricingStatus: status.IDLE,
    updatingClassPricingStatus: status.IDLE,
  },
  classDeletingStatus: status.IDLE,
  setActivationStatus: status.IDLE,
};

const slice = createSlice({
  name: 'classDetails',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    resetStatus(state, { payload }) {
      state.schedule[payload] = status.IDLE;
    },
    resetScheduleSubmittedValues(state) {
      state.schedule.submittedValuesForCalculation =
        initialState.schedule.submittedValuesForCalculation;
      state.schedule.scheduleCalculation =
        initialState.schedule.scheduleCalculation;
    },
    setTablePage: (state, { payload }) => {
      state.schedule.pagination.current = payload;
    },
    setScheduleEditMode(state, { payload }) {
      state.schedule.editMode = payload;
    },
    setPricingEditMode(state, { payload }) {
      state.pricing.editMode = payload;
    },
    setSubmittedValuesForCalculation(state, { payload }) {
      state.schedule.submittedValuesForCalculation = payload;
    },
    setDancersPage: (state, { payload }) => {
      state.dancers.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getClassInfo.pending, (state) => {
        state.loadingClassInfoStatus = status.PENDING;
      })
      .addCase(thunks.getClassInfo.fulfilled, (state, { payload }) => {
        state.classInfo = payload.data;
        state.loadingClassInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.getClassInfo.rejected, (state) => {
        state.loadingClassInfoStatus = status.FAIL;
      })

      .addCase(thunks.getClassAppointments.pending, (state) => {
        state.loadingAppointmentsStatus = status.PENDING;
      })
      .addCase(thunks.getClassAppointments.fulfilled, (state, { payload }) => {
        state.classAppointments = payload.data;
        state.loadingAppointmentsStatus = status.SUCCESS;
      })
      .addCase(thunks.getClassAppointments.rejected, (state) => {
        state.loadingAppointmentsStatus = status.FAIL;
      })

      .addCase(thunks.checkClassSchedule.pending, (state) => {
        state.schedule.checkingClassScheduleStatus = status.PENDING;
      })
      .addCase(thunks.checkClassSchedule.fulfilled, (state, { payload }) => {
        state.schedule.scheduleCalculation = payload.data;
        state.schedule.checkingClassScheduleStatus = status.SUCCESS;
      })
      .addCase(thunks.checkClassSchedule.rejected, (state) => {
        state.schedule.checkingClassScheduleStatus = status.FAIL;
      })

      .addCase(thunks.saveClassSchedule.pending, (state) => {
        state.schedule.savingClassScheduleStatus = status.PENDING;
      })
      .addCase(thunks.saveClassSchedule.fulfilled, (state) => {
        state.schedule.savingClassScheduleStatus = status.SUCCESS;
      })
      .addCase(thunks.saveClassSchedule.rejected, (state) => {
        state.schedule.savingClassScheduleStatus = status.FAIL;
      })

      .addCase(thunks.getClassScheduleList.pending, (state) => {
        state.schedule.getClassScheduleListStatus = status.PENDING;
      })
      .addCase(thunks.getClassScheduleList.fulfilled, (state, { payload }) => {
        const { originalData } = payload.data;
        state.schedule.list = payload.data.list.map((item, index) => {
          return {
            ...item,
            date: item.userLocalFormatted.userLocalDateFormatted,
            time: item.userLocalFormatted.userLocalTimeFormatted,
            duration: item.duration,
            id: index + 1,
          };
        });
        state.schedule.data = originalData
          ? scheduleDefaultValuesModel(originalData)
          : null;
        state.schedule.getClassScheduleListStatus = status.SUCCESS;
      })
      .addCase(thunks.getClassScheduleList.rejected, (state) => {
        state.schedule.getClassScheduleListStatus = status.FAIL;
      })

      .addCase(thunks.updateClassScheduleInfo.pending, (state) => {
        state.schedule.updateClassScheduleInfoStatus = status.PENDING;
      })
      .addCase(thunks.updateClassScheduleInfo.fulfilled, (state) => {
        state.schedule.updateClassScheduleInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.updateClassScheduleInfo.rejected, (state) => {
        state.schedule.updateClassScheduleInfoStatus = status.FAIL;
      })

      .addCase(thunks.getClassDancers.pending, (state) => {
        state.dancers.loadingClassDancersStatus = status.PENDING;
      })
      .addCase(thunks.getClassDancers.fulfilled, (state, { payload }) => {
        state.dancers.list = payload.data;
        state.dancers.loadingClassDancersStatus = status.SUCCESS;
      })
      .addCase(thunks.getClassDancers.rejected, (state) => {
        state.dancers.loadingClassDancersStatus = status.FAIL;
      })

      .addCase(thunks.deleteClass.pending, (state) => {
        state.classDeletingStatus = status.PENDING;
      })
      .addCase(thunks.deleteClass.fulfilled, (state) => {
        state.classDeletingStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteClass.rejected, (state) => {
        state.classDeletingStatus = status.FAIL;
      })

      .addCase(thunks.creatingClassPricing.pending, (state) => {
        state.pricing.creatingClassPricingStatus = status.PENDING;
      })
      .addCase(thunks.creatingClassPricing.fulfilled, (state, { payload }) => {
        state.pricing.data = pricingDefaultValuesModel(payload.data);
        state.pricing.creatingClassPricingStatus = status.SUCCESS;
      })
      .addCase(thunks.creatingClassPricing.rejected, (state) => {
        state.pricing.creatingClassPricingStatus = status.FAIL;
      })

      .addCase(thunks.getClassPricing.pending, (state) => {
        state.pricing.gettingClassPricingStatus = status.PENDING;
      })
      .addCase(thunks.getClassPricing.fulfilled, (state, { payload }) => {
        state.pricing.data = payload.data
          ? pricingDefaultValuesModel(payload.data)
          : null;
        state.pricing.gettingClassPricingStatus = status.SUCCESS;
      })
      .addCase(thunks.getClassPricing.rejected, (state) => {
        state.pricing.gettingClassPricingStatus = status.FAIL;
      })

      .addCase(thunks.updateClassPricing.pending, (state) => {
        state.pricing.updatingClassPricingStatus = status.PENDING;
      })
      .addCase(thunks.updateClassPricing.fulfilled, (state, { payload }) => {
        state.pricing.data = pricingDefaultValuesModel(payload.data);
        state.pricing.updatingClassPricingStatus = status.SUCCESS;
      })
      .addCase(thunks.updateClassPricing.rejected, (state) => {
        state.pricing.updatingClassPricingStatus = status.FAIL;
      })

      .addCase(thunks.setClassActivation.pending, (state) => {
        state.setActivationStatus = status.PENDING;
      })
      .addCase(thunks.setClassActivation.fulfilled, (state, { payload }) => {
        state.classInfo = payload.data;
        state.setActivationStatus = status.SUCCESS;
      })
      .addCase(thunks.setClassActivation.rejected, (state) => {
        state.setActivationStatus = status.FAIL;
      });
  },
});

const classDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { classDetails };
export default slice.reducer;
