import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  getConfigurationSettings: createThunk(
    'configuration/getConfigurationSettings',
    () => api.get(WEB_API_ROUTES.settings.config)
  ),

  updateConfigurationSettings: createThunk(
    'configuration/updateConfigurationSettings',
    (data) => api.put(WEB_API_ROUTES.settings.config, data)
  ),

  deleteLocation: createThunk('configuration/deleteLocation', (locationId) =>
    api.delete(
      WEB_API_ROUTES.settings.deleteLocation.replace('{locationId}', locationId)
    )
  ),

  deleteRoom: createThunk('configuration/deleteRoom', (roomId) =>
    api.delete(WEB_API_ROUTES.settings.deleteRoom.replace('{roomId}', roomId))
  ),
};
