import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RTable } from 'components/RTable';
import { RButton } from 'components/ui/RButton';
import { PLUS_SVG } from 'components/ui/_shared/SVG';
import { MultipleSelectModal } from 'pages/User/_shared/MultipleSelectModal';
import { useToggle } from 'hooks/useToggle';
import { performanceDetails } from 'store/performanceDetails';
import { status } from 'utils/const';
import { columns } from './config';
import { ActionWrap, Wrap } from './styled';

export const DancersTab = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showAddModal, toggleShowAddModal] = useToggle();

  const performanceInfo = useSelector(
    performanceDetails.selectors.performanceInfo
  );
  const list = useSelector(performanceDetails.selectors.dancers.list);
  const performanceDancers = useSelector(
    performanceDetails.selectors.dancers.performanceDancers
  );
  const currentPage = useSelector(
    performanceDetails.selectors.dancers.currentPage
  );
  const loadingPerformanceDancersStatus = useSelector(
    performanceDetails.selectors.dancers.loadingPerformanceDancersStatus
  );
  const loading = loadingPerformanceDancersStatus === status.PENDING;

  const preparedDancers = list.map((s) => ({
    ...s,
    value: s.id,
    label: s.full_name,
    path: s.photo,
  }));

  const onPageChange = (page) => {
    dispatch(performanceDetails.actions.setDancersPage(page));
  };

  const handleAddDancers = async (dancers) => {
    await dispatch(
      performanceDetails.thunks.updatePerformanceDancers({
        performanceId: id,
        dancers: dancers.map((d) => d.id),
      })
    ).unwrap();

    await dispatch(
      performanceDetails.thunks.getDancersList({
        routineId: performanceInfo.routine_id,
        performanceId: id,
      })
    );

    await dispatch(
      performanceDetails.thunks.getPerformanceDancers(id)
    ).unwrap();

    dispatch(performanceDetails.thunks.gePerformanceInfo(id));
  };

  useEffect(() => {
    if (!performanceInfo?.routine_id) return;

    dispatch(performanceDetails.thunks.getPerformanceDancers(id));
    dispatch(
      performanceDetails.thunks.getDancersList({
        routineId: performanceInfo.routine_id,
        performanceId: id,
      })
    );
    dispatch(performanceDetails.actions.setDancersPage(1));
  }, [performanceInfo.routine_id]);

  return (
    <Wrap>
      <ActionWrap>
        {showAddModal && (
          <MultipleSelectModal
            placeholder="Search for a Dancer"
            name="Update Dancers"
            options={preparedDancers}
            selectedOptions={preparedDancers.filter((d) => d.is_attached)}
            onSubmit={handleAddDancers}
            onClose={toggleShowAddModal}
          />
        )}

        <RButton
          onClick={toggleShowAddModal}
          css={{ padding: '11px 23px' }}
          prependIcon={
            <PLUS_SVG
              size={25}
              color={theme.gray25}
            />
          }
          disabled={loading}
        >
          Update Dancers
        </RButton>
      </ActionWrap>

      <RTable
        columns={columns}
        data={performanceDancers}
        paginationActive
        currentPage={currentPage}
        listEmptyMessage="There are no dancers yet"
        onPageChange={onPageChange}
        loading={loading}
      />
    </Wrap>
  );
};
