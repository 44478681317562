import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  getAppointments: createThunk('scheduler/getAppointments', ({ start, end }) =>
    api.get(WEB_API_ROUTES.schedule.appointments, {
      params: { start, end },
    })
  ),

  getParents: createThunk('scheduler/getParents', () =>
    api.get(WEB_API_ROUTES.schedule.parents)
  ),

  getClasses: createThunk('scheduler/getClasses', () =>
    api.get(WEB_API_ROUTES.schedule.classes)
  ),

  storeAppointment: createThunk('scheduler/storeAppointment', (data) =>
    api.post(WEB_API_ROUTES.schedule.createAppointment, data)
  ),

  updateAppointment: createThunk('scheduler/updateAppointment', (data) =>
    api.post(WEB_API_ROUTES.schedule.updateAppointment, data)
  ),

  deleteAppointment: createThunk(
    'scheduler/deleteAppointment',
    (appointmentId) =>
      api.delete(
        WEB_API_ROUTES.schedule.deleteAppointment.replace(
          '{appointmentId}',
          appointmentId
        )
      )
  ),

  removeAppointmentMedia: createThunk(
    'scheduler/removeAppointmentMedia',
    (medias) =>
      api.post(WEB_API_ROUTES.schedule.removeAppointmentMedia, { medias })
  ),

  rejectRequest: createThunk('scheduler/rejectRequest', ({ requestId }) =>
    api.post(WEB_API_ROUTES.schedule.rejectRequest, {
      request_id: requestId,
    })
  ),

  approveRequest: createThunk('scheduler/approveRequest', (data) =>
    api.post(WEB_API_ROUTES.schedule.approveRequest, data)
  ),

  sendEmail: createThunk(
    'scheduler/sendEmail',
    ({ subject, messageText, messageFor }) =>
      api.post(WEB_API_ROUTES.schedule.sendEmail, {
        messageFor,
        subject,
        messageText,
      })
  ),

  sendSms: createThunk('scheduler/sendSms', ({ messageText, messageFor }) =>
    api.post(WEB_API_ROUTES.schedule.sendSms, {
      messageText,
      messageFor,
    })
  ),

  cancelAppointment: createThunk(
    'scheduler/cancelAppointment',
    (appointmentId) =>
      api.delete(
        WEB_API_ROUTES.schedule.cancelAppointment.replace(
          '{appointmentId}',
          appointmentId
        )
      )
  ),

  updateRating: createThunk(
    'scheduler/updateRating',
    ({ type, value, studentId, scheduleId }) =>
      api.post(WEB_API_ROUTES.schedule.updateDancerRating, {
        type,
        value,
        student_id: studentId,
        schedule_id: scheduleId,
      })
  ),

  updateAttendance: createThunk(
    'scheduler/updateAttendance',
    ({ studentId, scheduleId, attendance }) =>
      api.post(WEB_API_ROUTES.schedule.updateAttendance, {
        student_id: studentId,
        schedule_id: scheduleId,
        attendance,
      })
  ),

  storeUnavailable: createThunk('scheduler/storeUnavailable', (data) =>
    api.post(WEB_API_ROUTES.schedule.storeUnavailable, data)
  ),

  updateUnavailable: createThunk('scheduler/updateUnavailable', (data) => {
    const appointmentId = data.get('appointment_id');
    data.delete('appointment_id');
    return api.post(
      WEB_API_ROUTES.schedule.updateUnavailable.replace(
        '{appointmentId}',
        appointmentId
      ),
      data
    );
  }),

  storeInternal: createThunk('scheduler/storeInternal', (data) =>
    api.post(WEB_API_ROUTES.schedule.storeInternal, data)
  ),

  updateInternal: createThunk('scheduler/updateInternal', (data) => {
    const appointmentId = data.get('appointment_id');
    data.delete('appointment_id');

    return api.post(
      WEB_API_ROUTES.schedule.updateInternal.replace(
        '{appointmentId}',
        appointmentId
      ),
      data
    );
  }),
};
