export const APP_ROUTES = {
  notFound: 'not-found',
  edit: 'edit',

  auth: {
    index: '/auth/*',
    logIn: 'login',
    createPassword: 'create-password',
  },

  user: {
    index: '/*',
    dashboard: '/',
    scheduler: '/scheduler',
    settings: '/settings',

    dancers: {
      index: '/dancers',
      create: '/dancers/create',
      notifications: 'dancers/:id/notifications',
      details: {
        index: '/dancers/:id/*',
        info: 'details',
        routines: 'routines',
        notifications: 'notifications',
        performances: 'performances',
      },
    },
    parents: {
      index: '/parents',
      create: '/parents/create',
      notifications: '/parents/:id/notifications',
      details: {
        index: '/parents/:id/*',
        info: 'details',
        notifications: 'notifications',
      },
    },
    classes: {
      index: '/classes',
      create: '/classes/create',
      details: {
        index: '/classes/:id/*',
        info: 'details',
        dancers: 'dancers',
        schedule: 'schedule',
        scheduleEdit: 'schedule/edit',
        pricing: 'pricing',
        pricingEdit: 'pricing/edit',
      },
    },
    routines: {
      index: '/routines',
      create: '/routines/create',

      details: {
        index: '/routines/:id/*',
        info: 'details',
        dancers: 'dancers',
        performances: 'performances',
      },
    },
    events: {
      index: '/events',
      create: '/events/create',

      details: {
        index: '/events/:id/*',
        info: 'details',
        performances: 'performances',
      },
    },
    performances: {
      create: '/performances/create',

      details: {
        index: '/performances/:id/*',
        info: 'details',
        dancers: 'dancers',
      },
    },
    media: {
      index: '/media',
    },
  },
};
