import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createParent } from 'store/createParent';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  submitParentCreation: createThunk(
    'createParent/submitParentCreation',
    (data) => api.post(WEB_API_ROUTES.parents.list, data),
    'New parent created'
  ),

  createRelative: createThunk(
    'createParent/createRelative',
    async ({ data, _id }, { dispatch }) => {
      const { data: relativeData } = await api.post(
        WEB_API_ROUTES.parents.createRelative,
        data
      );

      const newRelativeId = {
        value: relativeData.data.relative.id,
        label: `${relativeData.data.relative.first_name} ${relativeData.data.relative.last_name}`,
      };

      dispatch(createParent.actions.addCreatedRelative(newRelativeId));

      return {
        data: {
          relative: {
            ...relativeData.data.relative,
            id: newRelativeId,
            is_primary_contact: data.is_primary_contact,
          },
          _id,
        },
      };
    },
    'Child Saved'
  ),

  getRelatives: createThunk('createParent/getRelatives', () =>
    api.get(WEB_API_ROUTES.parents.getRelatives)
  ),
};
