import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  FieldWrap,
  HelperText,
  Label,
  LabelStatic,
  Wrap,
} from '../_shared/styled';
import { FieldIcons, FieldInput } from './styled';
import { SEARCH_SVG } from '../_shared/SVG';

RSearchField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  height: PropTypes.number,
  amountSymbols: PropTypes.number,
  variant: PropTypes.string,
  labelStatic: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperTextStatic: PropTypes.bool,
  onChange: PropTypes.func,
  disableDebounce: PropTypes.bool,
};

export function RSearchField({
  label,
  placeholder,
  value = '',
  helperText,
  height = 40,
  amountSymbols = 3,
  variant,
  labelStatic = true,
  error = false,
  fullWidth = false,
  helperTextStatic = true,
  onChange = () => {},
  disableDebounce = false,
}) {
  const ref = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const [fieldValue, setFieldValue] = useState(value || '');
  const timerId = useRef();

  const handleSetValue = (value) => {
    if (value.length >= amountSymbols) {
      onChange(value);
    }
    if (value.length === 0) {
      onChange(null);
    }
  };

  const onDebounce = (value) => {
    if (timerId.current) clearTimeout(timerId.current);
    timerId.current = setTimeout(() => {
      handleSetValue(value);
    }, 1000);
  };

  const onChangeFieldValue = (e) => {
    const { value } = e.target;
    setFieldValue(value);

    if (disableDebounce) {
      handleSetValue(value);
    } else {
      onDebounce(value);
    }
  };

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const isFilled = useMemo(() => {
    return fieldValue.length > 0;
  }, [fieldValue]);

  useEffect(() => {
    setFieldValue(value || '');

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [value]);

  return (
    <Wrap
      fullWidth={fullWidth}
      ref={ref}
    >
      {labelStatic && <LabelStatic error={error}>{label}</LabelStatic>}
      <FieldWrap>
        {label && !labelStatic && (
          <Label
            focus={isFocus}
            activated={isFilled}
            error={error}
          >
            {label}
          </Label>
        )}
        <FieldInput
          type="text"
          height={height}
          error={error}
          value={fieldValue}
          placeholder={placeholder}
          onChange={onChangeFieldValue}
          onFocus={onFocus}
          onBlur={onBlur}
          activated={isFilled}
          variant={variant}
          labelStatic
        />
        <FieldIcons>
          <SEARCH_SVG />
        </FieldIcons>
      </FieldWrap>
      {helperTextStatic && <HelperText>{error && helperText}</HelperText>}
    </Wrap>
  );
}
