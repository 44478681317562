import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';
import { apiWrapper } from '../../api/apiWrapper';

export const thunks = {
  getRoutineInfo: createThunk(
    'routineDetails/getRoutineInfo',
    ({ routineId }) =>
      apiWrapper.get(
        WEB_API_ROUTES.routines.routineInfo.replace('{routineId}', routineId)
      )
  ),

  updateRoutineInfo: createThunk(
    'routineDetails/updateRoutineInfo',
    ({ routineId, data }) =>
      api.put(
        WEB_API_ROUTES.routines.routineInfo.replace('{routineId}', routineId),
        data
      )
  ),

  uploadAudio: createThunk(
    'routineDetails/uploadAudio',
    ({ routineId, formData }) =>
      api.post(
        WEB_API_ROUTES.routines.audio.replace('{routineId}', routineId),
        formData
      )
  ),

  removeAudio: createThunk('routineDetails/removeAudio', (routineId) =>
    api.delete(WEB_API_ROUTES.routines.audio.replace('{routineId}', routineId))
  ),

  getRoutineDancers: createThunk(
    'routineDetails/getRoutineDancers',
    (routineId) =>
      api.get(WEB_API_ROUTES.routines.dancers.replace('{routineId}', routineId))
  ),

  updateRoutineDancers: createThunk(
    'routineDetails/updateRoutineDancers',
    ({ routineId, dancers }) =>
      api.put(
        WEB_API_ROUTES.routines.updateDancers.replace('{routineId}', routineId),
        { dancers }
      )
  ),

  deleteRoutine: createThunk('routineDetails/deleteRoutine', (routineId) =>
    api.delete(
      WEB_API_ROUTES.routines.routineInfo.replace('{routineId}', routineId)
    )
  ),

  sendEmail: createThunk(
    'routineDetails/sendEmail',
    ({ classId, subject, messageText }) =>
      api.post(WEB_API_ROUTES.classes.sendEmail.replace('{classId}', classId), {
        subject,
        messageText,
      })
  ),

  sendSms: createThunk('routineDetails/sendSms', ({ classId, messageText }) =>
    api.post(WEB_API_ROUTES.classes.sendSms.replace('{classId}', classId), {
      messageText,
    })
  ),

  getRoutinePerformances: createThunk(
    'routineDetails/getRoutinePerformances',
    (routineId) =>
      api.get(
        WEB_API_ROUTES.routines.performances.replace('{routineId}', routineId)
      )
  ),
};
