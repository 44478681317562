const selectors = {
  list: (state) => state.classes.list,
  recipientsList: (state) => state.classes.recipientsList,
  loadingListStatus: (state) => state.classes.loadingListStatus,
  loadingRecipientsStatus: (state) => state.classes.loadingRecipientsStatus,

  preparedEntities: (state) => state.classes.preparedEntities,
  filters: (state) => state.classes.filters,
  pagination: (state) => state.classes.pagination,
};

export { selectors };
