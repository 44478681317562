import { createSlice } from '@reduxjs/toolkit';

import { classesStyles, levels, status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

export const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const defaultValues = {
  className: { label: '', value: '' },
  teacherName: { label: '', value: '' },
  level: { label: '', value: '' },
  style: { label: '', value: '' },
  status: { label: statusOptions[0].label, value: statusOptions[0].value },
  dancer: { label: '', value: '' },
};

const initialState = {
  list: [],
  recipientsList: [],

  loadingListStatus: status.IDLE,
  loadingRecipientsStatus: status.IDLE,

  preparedEntities: {
    classesList: [],
    teachersList: [],
    levelsList: levels,
    stylesList: classesStyles,
  },
  filters: {
    fields: defaultValues,
    updatedFields: 0,
  },
  pagination: {
    current: 1,
  },
};

const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilters: (state, { payload }) => {
      let updatedFieldsCount = 0;
      const defaultValues = Object.values(initialState.filters.fields);

      Object.values(payload).forEach((field, index) => {
        if (field.value !== defaultValues[index].value) {
          updatedFieldsCount += 1;
        }
      });

      state.filters.updatedFields = updatedFieldsCount;
      state.filters.fields = payload;
    },
    setPage: (state, { payload }) => {
      state.pagination.current = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getList.pending, (state) => {
        state.loadingListStatus = status.PENDING;
      })
      .addCase(thunks.getList.fulfilled, (state, { payload }) => {
        const preparedData = payload.data.map(({ styles, ...rest }) => ({
          ...rest,
          styles: styles.map((s) => s.name).join('; '),
          location: rest?.location?.formattedLocation,
          inactive: !rest.is_active,
        }));

        const uniqueTeacherNames = new Set();

        const preparedFilters = preparedData.reduce(
          (acc, { id, name, teachers }) => {
            acc.classesList.push({ value: id, label: name });
            teachers.forEach(({ id, full_name, image }) => {
              if (!uniqueTeacherNames.has(full_name)) {
                uniqueTeacherNames.add(full_name);
                acc.teachersList.push({
                  value: id,
                  label: full_name,
                  path: image,
                });
              }
            });
            return acc;
          },
          { teachersList: [], classesList: [] }
        );

        state.preparedEntities.teachersList = preparedFilters.teachersList;
        state.preparedEntities.classesList = preparedFilters.classesList;

        state.list = preparedData;
        state.loadingListStatus = status.SUCCESS;
      })
      .addCase(thunks.getList.rejected, (state) => {
        state.loadingListStatus = status.FAIL;
      })

      .addCase(thunks.getClassRecipients.pending, (state) => {
        state.loadingRecipientsStatus = status.PENDING;
      })
      .addCase(thunks.getClassRecipients.fulfilled, (state, { payload }) => {
        state.loadingRecipientsStatus = status.SUCCESS;
        state.recipientsList = payload.data.map(
          ({ fullName, id, phone, email }) => ({
            email,
            phone,
            value: id,
            label: fullName,
          })
        );
      })
      .addCase(thunks.getClassRecipients.rejected, (state) => {
        state.loadingRecipientsStatus = status.FAIL;
      });
  },
});

const classes = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { classes };
export default slice.reducer;
