import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from './showToast';
import { handleServerErrors } from './server-errors';

export const createThunk = (type, apiCall, successMessage) =>
  createAsyncThunk(type, async (payload, thunkApi) => {
    try {
      const response = await apiCall(payload, thunkApi);

      if (successMessage) {
        showToast.success(successMessage);
      }

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  });
