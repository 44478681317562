import { createSlice } from '@reduxjs/toolkit';

import { eventTypes, resourceTypes } from 'utils/schedulerConsts';
import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  getAppointmentsStatus: status.IDLE,
  studentForPrivateCreate: null,
  classForGroupCreate: null,

  users: [],
  parents: [],
  appointments: [],

  filters: {
    generalEventTypes: ['appointments'], // ['appointments', 'requests'],
    resourceType: resourceTypes.teacher,
    eventTypes: [eventTypes.private, eventTypes.group, eventTypes.internal],
    locations: [],
    teachers: [],
    students: [],
  },
};

const slice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    setGeneralEventType(state, action) {
      state.filters.generalEventTypes = action.payload;
    },
    setResourceType(state, action) {
      state.filters.resourceType = action.payload;
    },
    setEventType(state, action) {
      state.filters.eventTypes = action.payload;
    },
    setLocations(state, action) {
      state.filters.locations = action.payload;
    },
    setTeachers(state, action) {
      state.filters.teachers = action.payload;
    },
    setStudents(state, action) {
      state.filters.students = action.payload;
    },
    setStudentForPrivateCreate(state, action) {
      state.studentForPrivateCreate = action.payload;
    },
    setClassForGroupCreate(state, action) {
      state.classForGroupCreate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(thunks.getAppointments.pending, (state) => {
        state.getAppointmentsStatus = status.PENDING;
      })
      .addCase(thunks.getAppointments.rejected, (state) => {
        state.getAppointmentsStatus = status.FAIL;
      })
      .addCase(thunks.getAppointments.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.getAppointmentsStatus = status.SUCCESS;
        state.appointments = data;
      })

      .addCase(thunks.getParents.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.parents = data;
      })
      .addCase(thunks.getClasses.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.classes = data;
      });
  },
});

const scheduler = {
  actions: slice.actions,
  selectors,
  thunks,
};

export { scheduler };
export default slice.reducer;
