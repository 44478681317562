import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const defaultValues = {
  title: '',
  progress_status: { label: '', value: '' },
  styles: [],
  styleSearch: { label: '', value: '' },
  level: { label: '', value: '' },
  type: { label: '', value: '' },
  typeSearch: { label: '', value: '' },
  teachers: [],
  teacherSearch: { label: '', value: '' },
  music_title: '',
  music_artist: '',
};

const initialState = {
  defaultRoutineInfo: defaultValues,
  submitCreationStatus: status.IDLE,
};

const slice = createSlice({
  name: 'createRoutine',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.submitCreation.pending, (state) => {
        state.submitCreationStatus = status.PENDING;
      })
      .addCase(thunks.submitCreation.fulfilled, (state) => {
        state.submitCreationStatus = status.SUCCESS;
      })
      .addCase(thunks.submitCreation.rejected, (state) => {
        state.submitCreationStatus = status.FAIL;
      });
  },
});

const createRoutine = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createRoutine };
export default slice.reducer;
