import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  getEventInfo: createThunk('eventDetails/getEventInfo', ({ eventId }) =>
    api.get(WEB_API_ROUTES.events.info.replace('{eventId}', eventId))
  ),

  sendEmail: createThunk(
    'eventDetails/sendEmail',
    ({ subject, messageText, messageFor }) =>
      api.post(WEB_API_ROUTES.events.sendEmail, {
        subject,
        messageText,
        messageFor,
      }),
    'Message sent'
  ),

  sendSms: createThunk(
    'eventDetails/sendSms',
    ({ messageText, messageFor }) =>
      api.post(WEB_API_ROUTES.dancers.sendSms, {
        messageText,
        messageFor,
      }),
    'Message sent'
  ),

  getEventPerformances: createThunk(
    'eventDetails/getEventPerformances',
    (eventId) =>
      api.get(WEB_API_ROUTES.events.performances.replace('{eventId}', eventId))
  ),
};
