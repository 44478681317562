import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  getParentsList: createThunk('parents/getParentsList', () =>
    api.get(WEB_API_ROUTES.parents.list)
  ),

  sendEmail: createThunk(
    'parents/sendEmail',
    ({ subject, messageText, messageFor }) =>
      api.post(WEB_API_ROUTES.parents.sendEmail, {
        messageFor,
        subject,
        messageText,
      }),
    'Message sent'
  ),

  sendSms: createThunk(
    'parents/sendSms',
    ({ messageText, messageFor }) =>
      api.post(WEB_API_ROUTES.parents.sendSms, {
        messageFor,
        messageText,
      }),
    'Message sent'
  ),
};
