import { createSlice } from '@reduxjs/toolkit';

import {
  classesStyles,
  levels,
  routineStatuses,
  routineTypes,
  status,
} from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const defaultValues = {
  teachers: [],
  dancers: [],
  style: { label: '', value: '' },
  type: { label: '', value: '' },
  status: { label: '', value: '' },
  level: { label: '', value: '' },
};

const initialState = {
  routinesList: [],
  loadingListStatus: status.IDLE,

  preparedEntities: {
    dancersList: [],
    teachersList: [],
    levelsList: levels,
    stylesList: classesStyles,
    typesList: routineTypes,
    statusesList: routineStatuses,
  },
  filters: {
    fields: defaultValues,
    updatedFields: 0,
  },
  pagination: {
    current: 1,
  },
};

const deepEqual = (a, b) => {
  if (a === b) return true;

  if (
    typeof a !== 'object' ||
    typeof b !== 'object' ||
    a === null ||
    b === null
  ) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  return (
    keysA.length === keysB.length &&
    keysA.every((key) => keysB.includes(key) && deepEqual(a[key], b[key]))
  );
};

const slice = createSlice({
  name: 'routines',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilters: (state, { payload }) => {
      let updatedFieldsCount = 0;
      const defaultValues = Object.values(initialState.filters.fields);

      Object.values(payload).forEach((field, index) => {
        if (!deepEqual(field, defaultValues[index])) {
          updatedFieldsCount += 1;
        }
      });

      state.filters.updatedFields = updatedFieldsCount;
      state.filters.fields = payload;
    },
    setPage: (state, { payload }) => {
      state.pagination.current = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getRoutinesList.pending, (state) => {
        state.loadingListStatus = status.PENDING;
      })
      .addCase(thunks.getRoutinesList.fulfilled, (state, { payload }) => {
        state.loadingListStatus = status.SUCCESS;

        const uniqueTeacherNames = new Set();
        const uniqueDancerNames = new Set();

        const preparedFilters = payload.data.reduce(
          (acc, { teachers, dancers }) => {
            dancers.forEach((d) => {
              if (!uniqueDancerNames.has(d.fullName)) {
                uniqueDancerNames.add(d.fullName);
                acc.dancersList.push({
                  ...d,
                  value: d.id,
                  label: d.fullName,
                  path: d.image,
                });
              }
            });

            teachers.forEach((t) => {
              if (!uniqueTeacherNames.has(t.full_name)) {
                uniqueTeacherNames.add(t.full_name);
                acc.teachersList.push({
                  ...t,
                  value: t.id,
                  label: t.full_name,
                  path: t.image,
                });
              }
            });
            return acc;
          },
          { teachersList: [], dancersList: [] }
        );

        state.preparedEntities.teachersList = preparedFilters.teachersList;
        state.preparedEntities.dancersList = preparedFilters.dancersList;

        const preparedData = payload.data.map(
          ({ styles, teachers, types, ...rest }) => ({
            ...rest,
            types: types?.join(', '),
            styles: styles?.map((s) => s.name).join(', '),
            teachers: teachers?.map((s) => s.full_name).join(', '),
          })
        );

        state.routinesList = preparedData;
      })
      .addCase(thunks.getRoutinesList.rejected, (state) => {
        state.loadingListStatus = status.FAIL;
      });
  },
});

const routines = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { routines };
export default slice.reducer;
