import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  sendVerifyCode: createThunk(
    'registration/sendVerifyCode',
    ({ to, email, phone }) => {
      const body = {
        to,
        ...(email && { email }),
        ...(phone && { phone }),
      };

      return api.post(WEB_API_ROUTES.registration.sendVerifyCode, body);
    }
  ),

  verifyCode: createThunk(
    'registration/verifyPhone',
    ({ code, email, phone, type }) =>
      api.post(WEB_API_ROUTES.registration.verifyCode, {
        code,
        type,
        ...(email && { email }),
        ...(phone && { phone }),
      })
  ),
};
