import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createDancer } from 'store/createDancer';
import { createThunk } from '../../utils/storeUtils';

const thunks = {
  submitDancerCreation: createThunk(
    'createDancer/submitDancerCreation',
    (data) => api.post(WEB_API_ROUTES.dancers.list, data),
    'New dancer created'
  ),

  createGuardian: createThunk(
    'createDancer/createGuardian',
    async ({ data, _id }, { dispatch }) => {
      const { data: guardianData } = await api.post(
        WEB_API_ROUTES.dancers.createRelative,
        data
      );

      const newRelativeId = {
        value: guardianData.data.relative.id,
        label: `${guardianData.data.relative.first_name} ${guardianData.data.relative.last_name}`,
      };

      dispatch(createDancer.actions.addRelative(newRelativeId));

      return {
        data: {
          relative: {
            ...guardianData.data.relative,
            id: newRelativeId,
            is_primary_contact: data.is_primary_contact,
          },
          _id,
        },
      };
    },
    'Parent Saved'
  ),

  removeDancerAvatar: createThunk('createDancer/removeDancerAvatar', (id) =>
    api.delete(WEB_API_ROUTES.dancers.removeAvatar.replace('{dancerId}', id))
  ),

  getRelatives: createThunk('createDancer/getRelatives', () =>
    api.get(WEB_API_ROUTES.dancers.getRelatives)
  ),
};

export { thunks };
