import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  logIn: createThunk('auth/logIn', (loginData) =>
    api.post(WEB_API_ROUTES.auth.logIn, {
      ...loginData,
      method: 'password',
    })
  ),

  signUp: createThunk('auth/signUp', (data) =>
    api.post(WEB_API_ROUTES.registration.signUp, data)
  ),

  refreshToken: createThunk('auth/refreshToken', (refresh_token) =>
    api.post(WEB_API_ROUTES.auth.refreshToken, { refresh_token })
  ),

  logout: createThunk('auth/logout', () =>
    api.post(WEB_API_ROUTES.auth.logOut)
  ),

  googleLogin: createThunk('auth/googleLogin', ({ token }) =>
    api.post(WEB_API_ROUTES.auth.googleLogin, { token })
  ),

  googleSignUp: createThunk('auth/googleSignUp', ({ token }) =>
    api.post(WEB_API_ROUTES.auth.googleSignUp, { token })
  ),

  appleLogin: createThunk('auth/appleLogin', ({ token }) =>
    api.post(WEB_API_ROUTES.auth.appleLogin, { token })
  ),

  appleSignUp: createThunk('auth/appleSignUp', ({ token }) =>
    api.post(WEB_API_ROUTES.auth.appleSignUp, { token })
  ),

  forgotPassword: createThunk('auth/forgotPassword', (email) =>
    api.post(WEB_API_ROUTES.auth.forgotPassword, { email })
  ),

  createPassword: createThunk(
    'auth/createPassword',
    ({ token, password, passwordConfirmation }) =>
      api.post(WEB_API_ROUTES.auth.createPassword, {
        token,
        password,
        passwordConfirmation,
      })
  ),
};
