import { format, fromUnixTime } from 'date-fns';
import { formatMilitaryToAMPM } from './times';

export const dateFromUnixToString = (unixDate, formatString) => {
  if (!formatString) {
    throw new Error('formatString is required');
  }

  if (unixDate && typeof unixDate === 'number') {
    // format unix to seconds
    return format(fromUnixTime(unixDate / 1000), formatString);
  }

  return null;
};

export const getPerformanceDateTime = (date, time, ampm = false) => {
  if (!date) return time;
  if (!time) return date;

  const preparedTime = ampm ? formatMilitaryToAMPM(time) : time;
  return `${date}; ${preparedTime}`;
};
